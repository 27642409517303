<div class="fixed top-0 left-0 w-full z-[53]">
	<div class="flex h-mobile justify-between items-center z-50 bg-white border-mainColor order-2 w-full">
		<span (click)="showSidebar = !showSidebar" class="cursor-pointer pl-3 flex items-center">
			<span *ngIf="showSidebar" class="material-icons close"> close </span>
			<span *ngIf="!showSidebar" class="material-icons" style="opacity: 1 !important"> menu </span>
		</span>
		<div class="self-center">
			<a [routerLink]="homepageRoute" (click)="routerSideActions($event)">
				<img ngSrc="/assets/logo.webp" alt="OSKAR" class="h-[40px]" height="40" width="138" />
			</a>
		</div>
		<div class="flex flex-row justify-between">
			<a [href]="href" class="!pr-3">
				<img
					ngSrc="/assets/mobile-icons/phone-icon.png"
					alt="phone-call"
					class="h-[20px] w-[20px]"
					height="20"
					width="20"
				/>
			</a>
		</div>
	</div>
</div>
<nav
	[@sidebarTrigger]="showSidebar ? 'open' : 'close'"
	class="bg-white rounded-br-sm fixed top-0 left-0 bottom-0 w-full h-full shadow-[8px_8px_18px_rgba(0,0,0,0.1)] z-[1001]"
>
	<div class="p-5 flex w-full h-[5vh] justify-center">
		<a routerLink="/" (click)="showSidebar = false"><img src="/assets/logo.webp" alt="OSKAR" class="h-[40px]" /></a>
		<span
			(click)="showSidebar = !showSidebar"
			class="flex justify-end cursor-pointer absolute right-0 top-0 items-center w-1/4 self-end p-5"
		>
			<span *ngIf="showSidebar" class="material-icons close"> close </span>
		</span>
	</div>
	<div class="w-full h-[95vh] flex flex-col justify-between p-5 overflow-y-scroll">
		<ul class="transition">
			<li *ngFor="let item of menuPositions">
				<ng-container *ngIf="item.childMenus?.length; else noSubItems">
					<div class="flex justify-between py-2 text-mainColor uppercase" (click)="showSubMenu(item.id)">
						<span class="font-bold">
							{{ item.anchor }}
						</span>
						<mat-icon *ngIf="item.childMenus?.length" class="cursor-pointer align-middle">
							expand_more
						</mat-icon>
					</div>
				</ng-container>
				<ng-template #noSubItems>
					<div class="flex justify-between py-2 text-mainColor uppercase">
						<a class="font-bold" [href]="item.link">
							{{ item.anchor }}
						</a>
					</div>
				</ng-template>

				<ul class="list-inside text-mainColor" *ngIf="expandedMenu.includes(item.id)">
					<li *ngFor="let subItem of item.childMenus" class="ml-4 py-1">
						<a class="font-bold" (click)="moveToSubItem(subItem, item.name)"> {{ subItem.anchor }}</a>
					</li>
				</ul>
			</li>
		</ul>
		<div class="h-[30%] border-t-2 border-accentColor">
			<div class="flex flex-row justify-between pt-2 text-mainColor">
				<p class="block text-lg font-extrabold">Kontakt</p>
				<div class="flex flex-col justify-end">
					<p class="text-right py-1">
						<a href="tel:+48618524572"> 61 852 45 72 </a>
					</p>
					<p class="text-right py-1">
						<a href="mailto:sprzedaz@oskar.com.pl"> sprzedaz&#64;oskar.com.pl </a>
					</p>
				</div>
			</div>
			<div class="flex flex-row justify-end pt-2 text-mainColor">
				<a
					href="https://www.facebook.com/BiuroPodrozyOSKAR"
					target="_blank"
					class="p-2 py-0.5"
					aria-label="Odwiedź naszą stronę na Facebooku"
				>
					<mat-icon>facebook</mat-icon>
				</a>
				<a
					href="https://www.instagram.com/oskar_biuropodrozy/"
					target="_blank"
					class="p-2 py-0.5"
					aria-label="Odwiedź nasz profil na Instagramie"
				>
					<mat-icon>photo_camera</mat-icon>
				</a>
				<a
					href="https://www.tiktok.com/@biuropodrozyoskar"
					target="_blank"
					class="p-2 py-0.5"
					aria-label="Odwiedź nasz profil na TikToku"
				>
					<mat-icon>tiktok</mat-icon>
				</a>
			</div>
			<!--			Commented for now-->
			<!--			<div-->
			<!--				class="py-2 flex flex-row justify-between items-center text-mainColor"-->
			<!--			>-->
			<!--				<a href="#" target="_blank" class="block text-lg font-extrabold"-->
			<!--					>Ulubione</a-->
			<!--				>-->
			<!--				<mat-icon class="text-red"> favorite</mat-icon>-->
			<!--			</div>-->
			<!--			<div-->
			<!--				class="py-2 flex flex-row justify-between items-center text-mainColor hidden"-->
			<!--			>-->
			<!--				<a href="#" target="_blank" class="block text-lg font-extrabold"-->
			<!--					>Zaloguj się</a-->
			<!--				>-->
			<!--				<mat-icon> person</mat-icon>-->
			<!--			</div>-->
		</div>
	</div>
</nav>
