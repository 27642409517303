import { OffersFacade } from '@/_store/offers/offers.facade';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NaviService, Position } from '../navi.service';

@Component({
	selector: 'app-navi-big',
	templateUrl: './navi-big.component.html',
	styleUrls: ['./navi-big.component.css'],
})
export class NaviBigComponent implements OnInit, OnDestroy {
	menu: Position[] = [];

	readonly homepageRoute = '/';
	private menuServiceSubscription: Subscription = new Subscription();

	constructor(
		public menuService: NaviService,
		private readonly router: Router,
		private readonly offersFacade: OffersFacade,
	) {}

	ngOnInit(): void {
		this.menuServiceSubscription = this.menuService.activeList$.subscribe((menu: Position[]) => {
			this.menu = menu;
		});
	}

	ngOnDestroy(): void {
		this.menuServiceSubscription.unsubscribe();
	}

	searchOffers(value: string): void {
		this.menuService.search(value);
	}

	routerSideActions(event: MouseEvent): void {
		const isCtrlPressed = event.ctrlKey || event.metaKey;
		const urlWithoutParams = this.router.url.split('?')[0];

		if (!isCtrlPressed && urlWithoutParams !== this.homepageRoute) {
			this.offersFacade.setInitialState();
		}
	}

	moveToSubItem(subitem: Position, groupName: string): void {
		this.menuService.moveToSubItem(subitem, groupName);
	}
}
