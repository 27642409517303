import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as generalActions from './general.actions';
import { Offer } from '@/main/main.component';
import { catchError, map, of, switchMap } from 'rxjs';
import { GeneralApiService } from '@/_api/general.api.service';

@Injectable()
export class GeneralDataEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly generalApiService: GeneralApiService,
	) {}

	fetchMatProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(generalActions.fetchSectionWWW),
			switchMap(() =>
				this.generalApiService.getSectionWWW().pipe(
					map((response) => response.results),
					map((sectionWWW: Offer[]) => generalActions.fetchSectionWWWSuccess({ sectionWWW })),
					catchError(() => of(generalActions.fetchSectionWWWError())),
				),
			),
		),
	);
}
