import { createReducer, on } from '@ngrx/store';
import * as generalActions from './general.actions';
import { Platform } from './general.types';
import { Offer } from '@/main/main.component';

export interface GeneralState {
	isLoggedIn: boolean;
	platform: Platform;
	sectionWWW: Offer[];
}

export const initialState: GeneralState = {
	isLoggedIn: false,
	platform: 'desktop',
	sectionWWW: [],
};

export const generalReducer = createReducer(
	initialState,
	on(generalActions.updateIsAuthenticated, (state, { isLoggedIn }) => ({
		...state,
		isLoggedIn,
	})),
	on(generalActions.updatePlatform, (state, { platform }) => ({
		...state,
		platform,
	})),
	on(generalActions.fetchSectionWWWSuccess, (state, { sectionWWW }) => ({
		...state,
		sectionWWW,
	})),
);
