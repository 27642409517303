import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DataService } from '../common/data.service';
import { NaviService } from '../navi/navi.service';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { TransferState } from '@angular/core';
import { IMAGES_KEY } from './main.guard';
import { SEOService } from '../common/seo.service';
import { EnvironmentService } from '@/services/environment/environment.service';
import { CurrentOfferFacade } from '@/_store/current-offer/current-offer.facade';
import { isPlatformServer } from '@angular/common';
import { GeneralDataFacade } from '@/_store/general/general.facade';

export interface Offer {
	kind: string;
	link: string;
	name: string;
	nameSuffix: string;
}

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit, OnDestroy {
	protected sectionWWW$ = this.generalDataFacade.sectionWWW$;
	private readonly subscription!: Subscription;

	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly api: DataService,
		public menuService: NaviService,
		private readonly meta: Meta,
		private readonly title: Title,
		private readonly transferState: TransferState,
		private readonly seoService: SEOService,
		private readonly environmentService: EnvironmentService,
		private readonly currentOfferFacade: CurrentOfferFacade,
		private readonly generalDataFacade: GeneralDataFacade,
	) {}

	ngOnInit(): void {
		const images = this.transferState.get(IMAGES_KEY, null);
		if (images) {
			images.forEach((image) => {
				const url = new URL(image.multimedia.url);
				this.seoService.setPreconnectLink(url.origin);
			});
		}
		const pageTitle =
			'Biuro podróży Poznań, wakacje autokarem oraz samolotem, wycieczki zagraniczne, oferty first minute.';
		const pageDescription =
			'Najlepsze oferty wczasów, wycieczek i objazdówek autokarem. Autokary klasy premium. Atrakcyjne ceny. Różne kierunki! Różne kierunki. Najlepsze Lokalizacje. Profesjonalni Kierowcy. Opieka pilota. Autokary Klasy Premium.';

		const url = this.environmentService.environment.production
			? 'https://oskar.com.pl'
			: 'https://dev.oskar.com.pl';

		this.meta.addTags(
			[
				{
					name: 'description',
					content: pageDescription,
				},
				{
					property: 'og:url',
					content: url,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:title',
					content: pageTitle,
				},
				{
					property: 'og:description',
					content: pageDescription,
				},
				{
					name: 'twitter:card',
					content: 'summary_large_image',
				},
				{
					name: 'twitter:domain',
					content: url,
				},
				{
					name: 'twitter:url',
					content: url,
				},
				{
					name: 'twitter:title',
					content: pageTitle,
				},
				{
					name: 'twitter:description',
					content: pageDescription,
				},
			],
			false,
		);

		this.title.setTitle(pageTitle);
		this.generalDataFacade.fetchSectionWWW();

		this.currentOfferFacade.clearCurrentOffer();

		this.setCanonicalLink();
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.seoService.removePreconnectLinks();
	}

	private setCanonicalLink(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		const index = window.location.href.indexOf('?');
		this.seoService.setCanonicalLink(window.location.href.slice(0, index), { withOrigin: true });
	}
}
