<section class="max-w-[1440px] h-60 mx-auto my-0 text-mainColor font-bold">
	<article class="w-[1440px] mx-auto my-[10px]">
		<div class="flex flex-row flex-wrap justify-between items-center mx-3">
			<div class="w-auto">
				<a [routerLink]="homepageRoute" (click)="routerSideActions($event)"
					><img ngSrc="/assets/logo.webp" alt="OSKAR" class="h-[60px]" height="60" width="208"
				/></a>
			</div>
			<div class="w-auto">
				<a href="mailto:sprzedaz@oskar.com.pl" class="text-mainColor hover:text-accentColor transition mx-2">
					<i class="fa-solid fa-envelope px-1"></i>
					sprzedaz&#64;oskar.com.pl
				</a>
				<a href="tel:+48618524572" class="text-mainColor hover:text-accentColor transition ms-2">
					<i class="fa-solid fa-phone px-1"></i> 61 852 45 72
				</a>
				<!--				<a-->
				<!--					href="#"-->
				<!--					class="text-mainColor hover:text-accentColor transition mx-2 hidden"-->
				<!--				>-->
				<!--					<i class="fa-solid fa-question px-1"></i>-->
				<!--					Pomoc-->
				<!--				</a>-->
				<!--				<a-->
				<!--					href="#"-->
				<!--					class="text-mainColor hover:text-accentColor transition mx-2 hidden"-->
				<!--				>-->
				<!--					<i class="fa-regular fa-heart px-1"></i>-->
				<!--					Ulubione-->
				<!--				</a>-->
				<!--				<a-->
				<!--					href="#"-->
				<!--					class="text-mainColor hover:text-accentColor transition mx-2 hidden"-->
				<!--					><i class="fa-solid fa-user px-1"></i>Zaloguj się</a-->
				<!--				>-->
			</div>
		</div>
	</article>
</section>
<div class="w-full bg-mainColor">
	<section class="max-w-[1440px] h-60 mx-auto my-0 text-white font-bold">
		<article class="w-[1440px] mx-auto my-[10px]">
			<div class="flex flex-row justify-between">
				<div class="flex-[0_0_auto] text-center">
					<ul class="m-0 p-0 flex flex-row justify-between">
						<li
							class="position inline-block py-0 px-5 m-0 transition cursor-pointer"
							(mouseenter)="item.childMenus?.length && menuService.displayMask()"
							(mouseleave)="item.childMenus?.length && menuService.hideMask()"
							*ngFor="let item of menu"
						>
							<a
								[href]="item.link"
								*ngIf="!item.childMenus?.length"
								class="h-[60px] leading-[60px] text-white hover:text-accentColor transition"
							>
								{{ item.anchor }}
							</a>
							<span
								*ngIf="item.childMenus?.length"
								class="h-[60px] leading-[60px] text-white hover:text-accentColor transition"
							>
								{{ item.anchor }}
								<mat-icon class="align-middle"> expand_more </mat-icon>
							</span>
							<div
								class="submenu absolute z-[9999] left-0 w-full transition max-h-0 overflow-hidden"
								*ngIf="item.childMenus?.length"
							>
								<div class="max-w-[1440px] my-0 mx-auto">
									<div class="mt-3 invisible"></div>
									<div class="bg-white rounded-lg py-[35px]">
										<div class="grid grid-cols-7 gap-4 mx-auto px-4">
											<div
												*ngFor="let subItem of item.childMenus"
												class="flex flex-col justify-between last-of-type:ju"
											>
												<a
													(click)="moveToSubItem(subItem, item.name)"
													class="leading-[45px] text-mainColor transition hover:text-accentColor font-bold text-left"
												>
													{{ subItem.anchor }}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="self-center">
					<div class="relative flex flex-row flex-wrap items-center w-full">
						<i
							class="absolute top-1/2 -translate-y-1/2 left-3 fa-solid fa-magnifying-glass text-white cursor-pointer hover:text-mainYellow"
							(click)="searchOffers(searchInput.value)"
						></i>
						<input
							#searchInput
							type="text"
							class="w-full bg-mainColor border-2 border-white rounded-3xl text-white pl-8 py-2 focus:border-accentColor focus:outline-none active:border-accentColor transition"
							(keydown.enter)="searchOffers(searchInput.value)"
							placeholder="Szukaj..."
						/>
					</div>
				</div>
			</div>
		</article>
	</section>
</div>
