import { createReducer, on } from '@ngrx/store';
import {
	DEFAULT_PAGINATION_OFFSET,
	DEFAULT_SORTING,
	NumericDictionary,
	OfferFilter,
	OfferList,
	Selected,
	SORTING_OPTIONS,
} from '@/_store/offers/offers.types';
import * as offersActions from '@/_store/offers/offers.actions';
import { Nights } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { setSelectedValueBaseOnParams } from '@/_store/offers/offers.utils';
import { combineNumericDictionaries } from '@/_utils/objects/combine-numeric-dictionaries';

export interface OffersState {
	filterOptions: OfferFilter;
	offersList: NumericDictionary<OfferList>;
	selected: Selected;
	availableBoardsDialog: { [key: string]: string };
	sorting: keyof typeof SORTING_OPTIONS;
	offset: number | null;
	count: number | null;
	nextPage: NumericDictionary<OfferList> | null;
	nextPageIsLoading: boolean;
}

export const initialState: OffersState = {
	filterOptions: {
		adults: [],
		boards: [],
		children: [],
		conveniences: [],
		count: 0,
		kinds: [],
		locations: [],
		nights: { min: [], max: [] },
		defaultNights: [],
		offersDateRange: { lower: '', upper: '' },
		ownTransport: false,
		products: [],
		promotionCatalogs: [],
		roomType: [],
		stops: [],
		tags: [],
		search: '',
	},
	selected: {
		adults: 0,
		boards: [],
		children: 0,
		conveniences: [],
		kinds: [],
		locations: [],
		nights: { max: 0, min: 0 },
		offersDateRange: { lower: '', upper: '' },
		ownTransport: false,
		roomType: [],
		stops: [],
		tags: [],
		search: '',
		status: null,
	},
	offersList: {},
	availableBoardsDialog: {},
	sorting: DEFAULT_SORTING,
	offset: DEFAULT_PAGINATION_OFFSET,
	count: null,
	nextPage: null,
	nextPageIsLoading: false,
};

export const offersReducer = createReducer(
	initialState,

	// FETCH
	on(offersActions.fetchOfferFilterSuccess, (state, { filterOptions }) => ({
		...state,
		filterOptions,
	})),
	on(offersActions.fetchOffersSuccess, (state, { offersList }) => ({
		...state,
		offersList: combineNumericDictionaries<OfferList>(state.offersList, offersList),
	})),
	on(offersActions.fetchBoardsSuccess, (state, { boards }) => ({
		...state,
		availableBoardsDialog: boards,
	})),
	on(offersActions.fetchNextOffersSuccess, (state, { nextPage }) => ({
		...state,
		nextPage,
	})),

	// FILTER OPTIONS ACTIONS
	on(offersActions.addLocationFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			locations: [...state.selected.locations, id],
		},
	})),
	on(offersActions.removeLocationFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			locations: state.selected.locations.filter((v) => v !== id),
		},
	})),
	on(offersActions.addKindsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			kinds: [...state.selected.kinds, id],
		},
	})),
	on(offersActions.removeKindsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			kinds: state.selected.kinds.filter((v) => v !== id),
		},
	})),
	on(offersActions.addBoardsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			boards: [...state.selected.boards, id],
		},
	})),
	on(offersActions.removeBoardsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			boards: state.selected.boards.filter((v) => v !== id),
		},
	})),
	on(offersActions.addTagsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			tags: [...state.selected.tags, id],
		},
	})),
	on(offersActions.removeTagsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			tags: state.selected.tags.filter((v) => v !== id),
		},
	})),
	on(offersActions.addConveniencesFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			conveniences: [...state.selected.conveniences, id],
		},
	})),
	on(offersActions.removeConveniencesFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			conveniences: state.selected.conveniences.filter((v) => v !== id),
		},
	})),
	on(offersActions.addStopsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			stops: [...state.selected.stops, id],
		},
	})),
	on(offersActions.removeStopsFilterOption, (state, { id }) => ({
		...state,
		selected: {
			...state.selected,
			stops: state.selected.stops.filter((v) => v !== id),
		},
	})),

	// UPDATE
	on(offersActions.updateOffersDateRange, (state, { dateRange }) => ({
		...state,
		selected: {
			...state.selected,
			offersDateRange: { ...dateRange },
		},
	})),
	on(offersActions.updateAdultsCount, (state, { adults }) => ({
		...state,
		selected: {
			...state.selected,
			adults: adults,
		},
	})),
	on(offersActions.updateChildrenCount, (state, { children }) => ({
		...state,
		selected: {
			...state.selected,
			children: children,
		},
	})),
	on(offersActions.updateNights, (state, { row }) => ({
		...state,
		selected: {
			...state.selected,
			nights: {
				...state.selected.nights,
				[row.type === Nights.FROM ? 'min' : 'max']: row.value,
			},
		},
	})),
	on(offersActions.updateNightsOptions, (state, { option }) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			nights: {
				...state.filterOptions.nights,
				[option.type === Nights.FROM ? 'max' : 'min']: [
					...state.filterOptions.defaultNights.filter((v) =>
						option.type === Nights.FROM ? v >= option.value && v > 0 : v <= option.value,
					),
				],
			},
		},
	})),
	on(offersActions.setDefaultNights, (state) => ({
		...state,
		filterOptions: {
			...state.filterOptions,
			nights: {
				min: state.filterOptions.defaultNights,
				max: state.filterOptions.defaultNights,
			},
		},
		selected: {
			...state.selected,
			nights: {
				min: Math.min(...state.filterOptions.defaultNights),
				max: Math.max(...state.filterOptions.defaultNights),
			},
		},
	})),
	on(offersActions.updateSelectedValues, (state, { selected }) => ({
		...state,
		selected: selected,
	})),
	on(offersActions.updateOwnTransport, (state, { ownTransport }) => ({
		...state,
		selected: { ...state.selected, ownTransport: ownTransport },
	})),
	on(offersActions.updateSearchFilter, (state, { search }) => ({
		...state,
		selected: { ...state.selected, search: search },
	})),
	on(offersActions.setInitialState, () => ({
		...initialState,
	})),
	on(offersActions.setSorting, (state, { sorting }) => ({
		...state,
		sorting,
	})),
	on(offersActions.updateCount, (state, { count }) => ({
		...state,
		count,
	})),
	on(offersActions.updateOffset, (state, { offset }) => ({
		...state,
		offset: isNaN(offset) ? null : offset,
	})),
	on(offersActions.updateOffers, (state, { offersList }) => ({
		...state,
		offersList: combineNumericDictionaries<OfferList>(state.offersList, offersList),
	})),
	on(offersActions.updateNextOffers, (state, { nextPage }) => ({
		...state,
		nextPage,
	})),
	on(offersActions.updateNextPageIsLoading, (state, { nextPageIsLoading }) => ({
		...state,
		nextPageIsLoading,
	})),

	// CLEAR
	on(offersActions.clearAvailableBoards, (state) => ({
		...state,
		availableBoardsDialog: null,
	})),
	on(offersActions.clearOfferList, (state) => ({
		...state,
		offersList: null,
	})),
	on(offersActions.clearBoardsFilter, (state, { boardId }) => ({
		...state,
		selected: {
			...state.selected,
			boards: boardId ? state.selected.boards.filter((board) => board !== boardId) : [],
		},
	})),
	on(offersActions.clearConveniencesFilter, (state, { convenienceId }) => ({
		...state,
		selected: {
			...state.selected,
			conveniences: convenienceId
				? state.selected.conveniences.filter((convenience) => convenience !== convenienceId)
				: [],
		},
	})),
	on(offersActions.clearStopsFilter, (state, { stopId }) => ({
		...state,
		selected: { ...state.selected, stops: stopId ? state.selected.stops.filter((stop) => stop !== stopId) : [] },
	})),
	on(offersActions.clearLocationsFilter, (state, { locationId }) => ({
		...state,
		selected: {
			...state.selected,
			locations: locationId ? state.selected.locations.filter((location) => location !== locationId) : [],
		},
	})),
	on(offersActions.clearKindsFilter, (state, { kindId }) => ({
		...state,
		selected: { ...state.selected, kinds: kindId ? state.selected.kinds.filter((kind) => kind !== kindId) : [] },
	})),
	on(offersActions.clearTagsFilter, (state, { tagId }) => ({
		...state,
		selected: { ...state.selected, tags: tagId ? state.selected.tags.filter((tag) => tag !== tagId) : [] },
	})),
	on(offersActions.clearSearchFilter, (state) => ({
		...state,
		selected: { ...state.selected, search: '' },
	})),
	on(offersActions.clearSelectedOptions, (state) => ({
		...state,
		selected: {
			...setSelectedValueBaseOnParams({ ...state.filterOptions }, {}),
		},
	})),
	on(offersActions.setDefaultChildrenValue, (state) => ({
		...state,
		selected: {
			...state.selected,
			children: Math.min(...state.filterOptions.children),
		},
	})),
	on(offersActions.setDefaultAdultValue, (state) => ({
		...state,
		selected: {
			...state.selected,
			adults: Math.min(...state.filterOptions.adults),
		},
	})),
	on(offersActions.clearOffersNextPage, (state) => ({
		...state,
		nextPage: null,
	})),
);
