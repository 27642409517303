import { Result } from '@/common/data.service';
import { Offer } from '@/main/main.component';
import { EnvironmentService } from '@/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GeneralApiService {
	private readonly environmentService = inject(EnvironmentService);
	private readonly httpClient = inject(HttpClient);
	private readonly api: string = this.environmentService.environment.apiUrl;

	getSectionWWW(): Observable<Result<Offer>> {
		return this.httpClient.get<Result<Offer>>(`${this.api}www/section_www/`);
	}
}
